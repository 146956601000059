import { Button, Group, Select, TextInput, Text, useMantineTheme, Center } from "@mantine/core";
import { nanoid } from "nanoid";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "../../../App";
import axios from "../../../utils/axios";
import SharedUtils from "../../../utils/shared";
import { Helmet } from "react-helmet";
import { Heading } from "@aws-amplify/ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { Upload } from "tabler-icons-react";

const Utils = new SharedUtils();


export default function CreateStaff(){
    const { state, dispatch} = useContext(AuthContext);
    const [parent, setParent] = useState(state.userData._id)
    const [title, setTitle] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [done, setDone] = useState(false);
    const [progress, setProgress] = useState("0")
    const [, setStep] = useState(0);

    useEffect(() => {
        ReactGA.send({hitType: "pageView", page: window.location.href})
    }, []);

    const createStaff = () => {
        // perform regex later
        if(title === ""){
            toast.error("Staff title is required");
            return false;
        }

        if(username === ""){
            toast.error("Staff email is required");
            return false;
        }
        try {
            const toastId = toast.loading("Please wait...", {
                position: "bottom-right"
            });
            const id = nanoid(64);
            const body = {
                title: title,
                username: username,
                parent: parent,
                parentUsername: state.userData.username,
                phone: phone,
                firstname: firstname,
                lastname: lastname,
                photo: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
                password: "12345678"//Utils.createDummyPasscode()
            };

            axios.post("/staff/create", body).then(function(res){
                axios.post("/tokens/create", {
                    username: username,
                    token: id
                }).then(function(res){
                    if(res.status === 200){
                        axios.post("/verify/send", {
                            fullname: firstname + " " + lastname,
                            username: username,
                            link: `https://app.emitasuite.com/staff/verify?token=${id}&email=${username}`
                        }).then(function(res){
                            if(res.status === 200){
                                setDone(true);
                                setStep(0);
                                toast.success(`Staff created and password reset link send to ${username}`, {
                                    id: toastId
                                });
                            }
                        }).catch(function(error){
                            toast.error(error.message, {
                                id: toastId
                            })
                        })
                    }
                }).catch(function(error){
                    toast.error(error.message, {
                        id: toastId
                    });
                })
            }).catch(function(error){
                toast.error(error.message, {
                    id: toastId
                });
            })
        } catch(error){
            toast.error(error.message, {
                position: "bottom-right"
            });
        }
    }

    const theme = useMantineTheme();

    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{state.userData.waterServiceName} | Onboard New Staff</title>
        </Helmet>
        <Group position="apart">
        <Heading fontWeight={650} level={6} marginBottom={20} marginTop={20} style={{color: theme.colorScheme === "dark" ? "white" : "black"}}>Onboard New Staff</Heading>

        </Group>
        {done ? (
            <>
            <Center mt={"10%"}>
                    <FontAwesomeIcon icon={faCheckCircle} size="xl" color="green" />
            </Center>
            <Text mt={20} mb={50} align="center">Success!</Text>
            <Center>
                <Button onClick={() => {
                            setUsername("");
                            setTitle("");
                            setFirstname("");
                            setLastname("");
                            setPhone("")
                            setDone(false)
                }}>Create New Staff</Button>
            </Center>

            </>
        ) : (
            <>
            {progress === "0" ? (
                <>
                <Select value={title} onChange={(val) => {setTitle(val)}} label="Title" description="Title of the staff e.g Technician" data={[
                    {label: "Technician", value: "technician"},
                ]} withAsterisk />

                <Group mt={50}>
                <Button onClick={() => {
                    title === "" ? (
                        toast.error("Please select the title of the staff", {
                            position: "bottom-right"
                        })
                    ) : setProgress("1")
                }}>Continue</Button>
                </Group>
                </>
            ) : progress === "1" ? (
                <>
                <TextInput value={username} onChange={(e) => {setUsername(e.currentTarget.value)}} label="Email" mb="md" description="Email address of the staff" withAsterisk />
                <TextInput value={firstname} onChange={(e) => {setFirstname(e.currentTarget.value)}} label="First name" mb="md" description="e.g John" withAsterisk />
                <TextInput value={lastname} onChange={(e) => {setLastname(e.currentTarget.value)}} label="Lastname" mb="md" description="e.g Doe" withAsterisk />
                <Group mt={50}>
                <Button variant="default" onClick={() => {setProgress("0")}}>Previous</Button>
                <Button onClick={() => {
                    username === "" ? (
                        toast.error("Please provide the email of the staff", {
                            position: "bottom-right"
                        })
                    ) : firstname === "" ? (
                        toast.error("Please provide the first name of the staff", {
                            position: "bottom-right"
                        })
                    ) : lastname === "" ? (
                        toast.error("Please provide the last name of the staff", {
                            position: "bottom-right"
                        })
                    ) : setProgress("2")
                }}>Continue</Button>
                </Group>
                </>
            ) : (
                <>
                <TextInput value={phone} onChange={(e) => {setPhone(e.currentTarget.value)}} label="Phone Number" description="Valid phone number of the staff" withAsterisk />
                <Group mt={50}>
                <Button variant="default" onClick={() => {setProgress("1")}}>Previous</Button>
                <Button disabled={title === "" || username === "" || firstname === "" || lastname === "" || phone === ""} onClick={() => {createStaff()}}>Create Staff</Button>
                </Group>
                </>
            )}
            </>
        )}
        </>
    )
}
